import "./App.css";
import "@fontsource/archivo";
import React, { useEffect } from "react";
import NavBar from "./components/NavBar/NavBar";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import CareerPage from "./Pages/CareerPage";
import ContactPage from "./Pages/ContactPage";
import ProductPage from "./Pages/ProductPage";
import theme from "./theme";
import NewsPage from "./Pages/NewsPage";
import ClientPage from "./Pages/ClientPage";
import ReactGA from "react-ga4";
import { initGA, logPageView } from "./analytics";

function App() {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/client" element={<ClientPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;

import React from "react";
import {
  Flex,
  Text,
  ListItem,
  Grid,
  Icon,
  Box,
  UnorderedList,
  Divider,
} from "@chakra-ui/react";
import styles from "./ProductPage.module.css";
import Footer from "../components/Footer";
import { GiWeight, GiStrong, GiBigWave } from "react-icons/gi";
import { TbMagnetOff } from "react-icons/tb";
import { ImLeaf } from "react-icons/im";

const HomePage = () => {
  return (
    <Flex className={styles.container}>
      <img className={styles.img} src="RUKO-1.jpg" />

      <Flex className={styles.headerContainer}>
        <Text className={styles.headerTitleText}>WHAT IS GFRP Rebar?</Text>
        <Text className={styles.headerDescText}>
          GFRP rebar, also known as Glass Fiber Reinforced Polymer rebar, is an
          innovative construction material that serves as a corrosion-free
          alternative to traditional steel reinforcement. Comprising
          high-strength glass fibers embedded in a polymer matrix, GFRP rebar
          offers exceptional tensile strength while remaining impervious to
          corrosion. Unlike steel rebar, it does not rust or deteriorate when
          exposed to moisture or chemicals, ensuring the longevity and
          durability of concrete structures.
        </Text>
        <Text className={styles.headerDescText}>
          One of the remarkable advantages of GFRP rebar lies in its lightweight
          composition, weighing only a fraction of steel rebar while maintaining
          an impressive strength-to-weight ratio. This attribute facilitates
          easier handling, transportation, and installation, leading to reduced
          labor and equipment costs. Moreover, GFRP rebar is electrically
          non-conductive and does not interfere with electromagnetic fields,
          making it an ideal choice for structures where electrical or magnetic
          systems are in place.
        </Text>
      </Flex>

      <Flex className={styles.descContainer} background="blackAlpha.300">
        <Text className={styles.descTitleText}>
          Steel Rebar is NOT the Solution
        </Text>
        <Text className={styles.headerDescText}>
          100 years ago, steel rebar was developed as the only option for
          reinforcing concrete. However, many of the structures developed before
          steel rebar are still standing today. Structures using steel rebar in
          corrosive environments will begin to fail after 10 years. Corrosion
          costs the private and public sectors hundreds of billions of dollars
          in repair and maintenance costs a year. Corroding steel rebar is a
          ticking time bomb because determining the moment of failure can mean
          the difference between life and death. GCR-BAR can save the current
          costs of corrosion and eliminate all failures due to corrosion because
          it will outlive the concrete it is reinforcing.
        </Text>
      </Flex>
      <img className={styles.descImg} src="app2.jpg" />

      <Flex className={styles.vsContainer}>
        <Text className={styles.vsTitleText} color="blackAlpha.700">
          GCR-BAR vs. Steel Rebar
        </Text>
      </Flex>

      <Flex className={styles.vsDescContainer}>
        <Flex className={styles.iconTextItem}>
          <Icon as={GiWeight} w={24} h={24} color="white" />
          <Text className={styles.iconText}>
            1/4 THE WEIGHT OF STEEL Get 4x the amount of product on your trucks
            and save incredibly on transportation costs. GCR-BAR is also
            significantly easier to handle and use.
          </Text>
        </Flex>
        <Flex className={styles.iconTextItem}>
          <Icon as={GiStrong} w={24} h={24} color="white" />
          <Text className={styles.iconText}>
            3x STRONGER THAN STEEL With a tensile strength 3x the amount of
            steel and a fatigue resistance 20x the amount of steel, GCR-BAR is
            the trusted solution for any project.
          </Text>
        </Flex>
        <Flex className={styles.iconTextItem}>
          <Icon as={GiBigWave} w={24} h={24} color="white" />
          <Flex flexDirection="column">
            <Text className={styles.iconText}>
              NON CORROSIVE AND NON CONDUCTIVE
            </Text>
            <Text className={styles.iconText}>
              GCR-BAR will not rust or corrode in these harsh environments,
              unlike steel rebar, which can corrode and weaken over time. This
              makes GFRP rebar a more durable and long-lasting material that can
              help to extend the lifespan of structures.
            </Text>
          </Flex>
        </Flex>
        <Flex className={styles.iconTextItem}>
          <Icon as={TbMagnetOff} w={24} h={24} color="white" />
          <Text className={styles.iconText}>
            GCR-BAR is a non-magnetic and non-conductive material that is made
            of glass fibers and a polymer resin. It is ideal for use in
            applications where magnetic interference or electrical shock is a
            concern, such as MRI machines, power lines, and telecommunications
            equipment.
          </Text>
        </Flex>
        <Flex className={styles.iconTextItem}>
          <Icon as={ImLeaf} w={24} h={24} color="white" />
          <Text className={styles.iconText}>
            GREENER SOLUTIONS The manufacturing process of GCR-BAR produces far
            less carbon emissions than the steel industry which has a
            devastating impact on the environment
          </Text>
        </Flex>
      </Flex>

      {/* Product Section */}
      <Flex className={styles.productContainer}>
        <Text className={styles.productTitleText}>Product</Text>
        <Flex className={styles.productSection}>
          <Flex className={styles.prodDesc}>
            <img className={styles.gcbLogo} src="LOGO_GCB.png" />
            <Text className={styles.straightBarText}>STRAIGHT BAR</Text>
            <UnorderedList spacing={3}>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Structural and non-structural rebar
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Available in custom lengths (5m - 150m)
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Diameters in stock: 6mm - 16mm
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Custom diameter up to 16mm
                </Text>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex className={styles.measurementContainer}>
            <Text className={styles.measurementTitle}>WEIGHT/METER</Text>
            <Flex className={styles.measurementSection}>
              <Flex className={styles.measurementItem}>
                <Text className={styles.measurementText}>6mm</Text>
                <Text className={styles.measurementText}>8mm</Text>
                <Text className={styles.measurementText}>10mm</Text>
                <Text className={styles.measurementText}>12mm</Text>
                <Text className={styles.measurementText}>16mm</Text>
              </Flex>

              <Divider
                className={styles.measurementDivider}
                orientation="vertical"
                borderColor="white"
              />
              <Flex className={styles.measurementItem}>
                <Text className={styles.measurementText}>0.067</Text>
                <Text className={styles.measurementText}>0.114</Text>
                <Text className={styles.measurementText}>0.168</Text>
                <Text className={styles.measurementText}>0.222</Text>
                <Text className={styles.measurementText}>0.379</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* Product Images */}
      <Flex className={styles.imagesContainer}>
        <img src="/product1.jpg" className={styles.imageSection} />
        <img src="/product2.jpg" className={styles.imageSection} />
        <img src="/product3.jpg" className={styles.imageSection3} />
      </Flex>

      {/* Application  */}
      <Flex
        className={styles.applicationContainer}
        backgroundColor="blackAlpha.300"
      >
        <Flex flexDirection="column">
          <Text className={styles.appTitleText}>Applications</Text>
          <Text className={styles.appDescText}>
            GCR-BAR is accepted to be used anywere that steel rebar is used. It
            is especially necessary to use in coastal areas, near high voltage
            currents and near magnetic fields.
          </Text>

          <Grid className={styles.applicationsGrid}>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="dams.jpg" />
              <Text className={styles.gridText}>DAMS</Text>
            </Flex>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="buildings.jpg" />
              <Text className={styles.gridText}>BUILDINGS</Text>
            </Flex>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="buildings.jpg" />
              <Text className={styles.gridText}>ROADS AND BRIDGES</Text>
            </Flex>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="pools.jpg" />
              <Text className={styles.gridText}>POOLS</Text>
            </Flex>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="parking.jpg" />
              <Text className={styles.gridText}>PARKING GARAGES</Text>
            </Flex>
            <Flex className={styles.gridItem}>
              <img className={styles.appImg} src="bridge.jpg" />
              <Text className={styles.gridText}>PIERS</Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>

      {/* Request Sample Section*/}
      <Flex className={styles.requestContainer}>
        <Flex className={styles.requestTextSection}>
          <Text className={styles.requestHeaderText}>Request A Sample?</Text>
          <Text className={styles.contactText}>
            Contact our team to get a sample of our products{" "}
            <a
              href="https://wa.me/6282136055778"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>
          </Text>
        </Flex>
        <img className={styles.requestImg} src="GFRP-rebar.png" />
      </Flex>

      <Footer />
    </Flex>
  );
};

export default HomePage;

import React from "react";
import { Flex, Text, Icon, Link, Divider } from "@chakra-ui/react";
import {
  CiInstagram,
  CiFacebook,
  CiTwitter,
  CiLinkedin,
  CiPhone,
  CiMail,
  CiLocationOn,
} from "react-icons/ci";
import styles from "./Footer.module.css";
import { HashLink } from "react-router-hash-link";

const scrollToId = (id) => {
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
};

const Footer = () => {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.footer}>
        {/* Logo and Socials */}
        <Flex className={styles.logoAndSocials}>
          <img className={styles.logoImg} src="logo_white_2.png"></img>
          <Flex className={styles.socialMediaContainer}>
            <a target="_blank" href="https://www.instagram.com/">
              <Icon as={CiInstagram} w={8} h={8} />
            </a>
            <a target="_blank" href="https://www.facebook.com/">
              <Icon as={CiFacebook} w={8} h={8} />
            </a>
            <a target="_blank" href="https://www.twitter.com/">
              <Icon as={CiTwitter} w={8} h={8} />
            </a>
            <a target="_blank" href="https://www.linkedin.com/">
              <Icon as={CiLinkedin} w={8} h={8} />
            </a>
          </Flex>
        </Flex>

        {/* Page Navigation  */}
        <Flex className={styles.navSection}>
          <a href="/">
            <Text className={styles.navText}>Home</Text>
          </a>

          <HashLink to="/home#about">
            <Text className={styles.navText}>About</Text>
          </HashLink>

          <a href="/product">
            <Text className={styles.navText}>Product</Text>
          </a>

          <a href="/careers">
            <Text className={styles.navText}>Careers</Text>
          </a>
          <a href="/contact">
            <Text className={styles.navText}>Contact Us</Text>
          </a>
        </Flex>
      </Flex>
      <Divider
        orientation="vertical"
        borderColor="white"
        borderWidth="1px"
        borderRadius="2px"
        marginY="24px"
        marginX="48px"
      />

      {/* Phone, Mail and Location Section  */}
      <Flex className={styles.contactsSection}>
        <Flex flexDirection="column" alignItems="center">
          <Icon
            as={CiPhone}
            w={10}
            h={10}
            color="#e93d27"
            backgroundColor="white"
            borderRadius="20px"
            margin="8px"
            padding="2px"
          />
          <a href="https://wa.me/6282136055778" target="_blank">
            <Text className={styles.contactText}>+62 82136055778</Text>
          </a>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Icon
            as={CiMail}
            w={10}
            h={10}
            color="#e93d27"
            backgroundColor="white"
            borderRadius="20px"
            margin="8px"
            padding="2px"
          />
          <a href="mailto:info@kuriacomposit.com" target="_blank">
            <Text className={styles.contactText}>info@kuriacomposit.com</Text>
          </a>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Icon
            as={CiLocationOn}
            w={10}
            h={10}
            color="#e93d27"
            backgroundColor="white"
            borderRadius="20px"
            margin="8px"
            padding="2px"
          />
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Jl.+Singotoro+Raya+No.39,+Karanganyar+Gn.,+Kec.+Candisari,+Kota+Semarang,+Jawa+Tengah+50256/@-7.0167673,110.4317513,17z/data=!3m1!4b1!4m6!3m5!1s0x2e708d203d6c45b5:0x67a5a12483832ad3!8m2!3d-7.0167726!4d110.4343262!16s%2Fg%2F11sckdc9sc?entry=ttu"
          >
            <Text className={styles.contactText}>Singotoro Raya No 39</Text>
            <Text className={styles.contactText}>Semarang, Jawa Tengah</Text>
            <Text className={styles.contactText}>Indonesia, 50256</Text>
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;

import React from "react";
import { Flex, Text, UnorderedList, ListItem, Divider } from "@chakra-ui/react";
import styles from "./HomePage.module.css";
import Footer from "../components/Footer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReactGA from "react-ga4";

const HomePage = () => {
  return (
    <Flex className={styles.container}>
      <img className={styles.img} src="work.jpeg" />

      {/* About Us Section */}
      <Flex id="about" className={styles.aboutUs}>
        <a name="about"></a>
        <Text className={styles.aboutTitleText}>About Us</Text>
        <Divider
          orientation="horizontal"
          borderColor="#e93d27"
          borderWidth="1px"
          borderRadius="2px"
          marginTop="8px"
          marginBottom="24px"
          width="250px"
        />

        <Text className={styles.aboutText}>
          Indonesia’s construction industry is one of the largest and
          fastest-growing in Asia, with a huge demand for infrastructure
          development and urbanization. Currently, the Indonesian construction
          market size is valued at 267 Billion USD or 4,049 Trillion Rupiah.
          However, the sector also faces many challenges, such as rising costs
          of materials and labor, environmental regulations, quality standards,
          and safety issues.
        </Text>
        <Text className={styles.aboutText}>
          One of the key materials used in concrete reinforcement is steel
          rebar, which accounts for a significant portion of the construction
          costs and has several drawbacks, such as corrosion, weight,
          conductivity, and thermal expansion. GCR-BAR is a fiberglass-based
          reinforcement material that offers a better solution for concrete
          structures.
        </Text>
        <Text className={styles.aboutText}>
          GCR-BAR is stronger, lighter, and more durable than steel rebar. It is
          also rustproof, non-conductive, and resistant to thermal expansion.
          GCR-BAR can reduce construction costs by up to 30-40%, extend the
          service life of structures by up to 50%, and lower the environmental
          impact by reducing CO2 emissions.
        </Text>
      </Flex>
      <img className={styles.img} src="RUKO-4.jpg" />

      {/* Vision Mission Section */}
      <Flex className={styles.visionContainer}>
        <Text className={styles.visionTitle}>Our Vision</Text>
        <Text className={styles.visionText}>
          Become the leading producer of high-quality composite parts for the
          construction industry, providing innovative solutions that enhance the
          strength, durability, and sustainability of building structures.
        </Text>
      </Flex>
      <Flex className={styles.missionContainer}>
        <Text className={styles.missionTitle}>Our Mission</Text>
        <Text className={styles.missionText}>
          Become the leading producer of high-quality composite parts for the
          construction industry, providing innovative solutions that enhance the
          strength, durability, and sustainability of building structures.
        </Text>
      </Flex>
      <img className={styles.img} src="app2.jpg" />

      {/* Product Section */}
      <Flex className={styles.productContainer}>
        <Text className={styles.aboutTitleText}>Product</Text>
        <Flex className={styles.productSection}>
          <Flex className={styles.prodDesc}>
            <img className={styles.gcbLogo} src="LOGO_GCB.png" />

            <UnorderedList spacing={2}>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Structural and non-structural rebar
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Available in custom lengths (5m - 150m)
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Diameters in stock: 6mm - 16mm
                </Text>
              </ListItem>
              <ListItem>
                <Text className={styles.prodDescText}>
                  Custom diameter up to 16mm
                </Text>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex className={styles.prodImgContainer}>
            <img className={styles.productImg1} src="product3.jpg" />
            <img className={styles.productImg2} src="product2.jpg" />
          </Flex>
        </Flex>
        <Text className={styles.prodDescText} paddingTop="24px">
          Learn more about our product{" "}
          <a href="/product" className={styles.hereText}>
            here.
          </a>
        </Text>
      </Flex>

      {/* Project Section */}
      <Flex
        className={styles.projectContainer}
        backgroundColor="blackAlpha.300"
      >
        <Text className={styles.projectTitle}>Our Projects</Text>
        <Flex className={styles.projectSection}>
          <Flex className={styles.projectItem}>
            <Text className={styles.projectNameText}>
              Jatengland Industrial Park
            </Text>
            <Text className={styles.projectDescText}>
              Kuria Composite's innovative GCR-BAR has been instrumental in
              shaping Jatengland Industrial Park Sayung. Our fiberglass-based
              reinforcement material, superior to traditional steel rebar, has
              been a key component in constructing many of Jatengland's durable
              and cost-effective structures, showcasing both sustainability and
              innovation.
            </Text>

            <Text className={styles.prodDescText} paddingTop="24px">
              Learn more about
              <a
                href="https://jatengland.com/about-us/"
                className={styles.jatenglandText}
              >
                Jatengland.
              </a>
            </Text>
          </Flex>
          <Divider
            className={styles.projectDivider}
            orientation="vertical"
            borderColor="#e93d27"
            borderWidth="1px"
            borderRadius="2px"
            height="100%"
            marginX="48px"
          />

          <img className={styles.projectImg} src="JALAN-2.jpg" />
        </Flex>
        <img className={styles.projectImg2} src="JALAN-2.jpg" />
      </Flex>

      <Footer />
    </Flex>
  );
};

export default HomePage;
